.about{

  &__container{
    padding: 32px 100px 85px;
    background-color: $aqua;
    margin-bottom: 60px;

    &.page{
      margin-bottom: 0px;
      padding: 32px 100px 15px;
    }

    &__text{
      line-height: 1.2;
      font-size: 20px;

      ul{
        padding-left: 40px;
      }
    }

    &__more{
      font-family: $ubuntu;
      text-align: center;
      color: $white;

      span{
        cursor: pointer;
      }
    }

    &__advantages{
      text-align: center;
      color: $blue;
      font-family: $ani;
      font-size: 45px;
      line-height: 45px;
      margin-top: 30px;

      div{

        &:first-child{
          margin-bottom: 5px;
          font-size: 95px;
          line-height: 95px;
        }

      }
    }


  }

  &__advantages{
    background-color: $aqua;
    padding: 50px 0 60px;

    .about__container__advantages{
      margin-top: 0;
    }
  }

  .big-title{
    margin-top: 55px;
    margin-bottom: 35px;
  }

}