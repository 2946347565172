.partners{

  .big-title{

    svg{
      height: 60px;
      width: 18px;
      margin-top: -14px;
    }
  }

  &__contaier{
    margin-bottom: 50px;
  }


  &__carousel{

    &__item{
      width: 175px;
      margin: auto;

      img{
        margin: 0 auto;

      }
    }
    .owl-carousel .owl-stage {
      display: flex;
      align-items: center;

      .owl-item img {
        width: auto;
      }
    }


    .owl-next{
      position: absolute;
      top: calc(50% - 50px);
      right: -50px;
    }

    .owl-prev{
      position: absolute;
      top: calc(50% - 50px);
      left: -50px;
    }

    .owl-theme .owl-nav [class*=owl-]:hover {
      background: none;
    }
    .big-title {
      margin-bottom: 15px;
    }

  }


  &__block{
    flex: 0 0 20%;
    max-width: 20%;
    text-align: center;
    padding: 0 15px;

    &__name{
      font-family: $ani;
      font-size: 30px;
      margin-bottom: 30px;
    }

  }


  &__back{
    padding: 15px;
    border: solid #ddd 1px;
    margin-bottom: 30px;
    min-height: 105px;
  }

}