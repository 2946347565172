.search{

  &__page{

    img{
      margin-right: 15px;
      box-shadow: $shadow;
      -webkit-border-radius: $img-radius;
      -moz-border-radius: $img-radius;
      border-radius: $img-radius;
    }

    .tags{
      margin-bottom: 0;

      a{
        background: #a7dfe6;
      }
    }
  }

  &__more{
    font-size: 18px;
  }

  &__container{
    padding: 35px;
    background-color: $yellow;
  }


}