.single{

  &__container{
    background-color: $aqua;
    padding: 60px 45px 60px;
  }
  &__post {
    font-size: 18px;
  }

    &__content {

      min-height: 300px;
    }

    &__img {
      float: left;
      padding-right: 15px;
      margin-top: 7px;

      img{
        box-shadow: $shadow;
        -webkit-border-radius: $img-radius;
        -moz-border-radius: $img-radius;
        border-radius: $img-radius;
      }

    }

    .tags {

      a {
        background: $daqua;
      }

    }

    &__btn {
      margin-top: 15px;

      .btn-blue{
        margin-bottom: 10px;
      }

      a {
        margin: 5px 0;
      }

    }

    .btn-blue-short,
    .btn-blue-long,
    .btn-blue-extra {
      display: inline-block;
      font-size: 20px;
      min-height: 50px;
      min-width: 200px;
    }

    .tags {
      margin-bottom: 15px;
      z-index: 9;
      position: relative;
      margin-top: -15px;
    }

  &__events{
    font-weight: bold;

    span{
      font-style: italic;
      font-weight: 400;
    }
    a{
      font-weight: bold;
    }
  }



}
