.news{

  &__container{
    background-color: $aqua;
    padding: 60px 30px 60px 40px;
  }

  &__text{
    font-size: 26px;
    line-height: 1.125;

    ul{
      padding-left: 30px;
    }
  }

  &__tag{
    font-size: 18px;
    list-style: none;


    li{
      margin-right: 7px;
      margin-bottom: 14px;
    }


    a{
      background: $daqua;
      border-radius: 50px;
      padding: 5px 15px;
    }
  }

  &__btn{

    span{
      cursor: pointer;
    }

    a{
      display: inline-block;
      padding: 14px 44px;
      font-size: 20px;
    }
  }

  &__img{
    float: left;
    padding-right: 40px;
    padding-bottom: 10px;

    img{
      box-shadow: $shadow;
      -webkit-border-radius: $img-radius;
      -moz-border-radius: $img-radius;
      border-radius: $img-radius;
    }

  }



}