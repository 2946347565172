.footer{
  text-align: center;

  &__container{
    background: url("../images/footer.png") no-repeat;
    background-size: cover;
    padding: 20px;
  }

  &__menu{
    text-align: left;
    list-style: none;
    margin: 0;
    padding-left: 50px;

    li{
      margin-bottom: 10px;
    }

    a{
      color: $white;
    }
  }

  &__social{
    @extend .header__social;
  }

  .svg{

    &:hover{

      svg{
        fill:$aqua;
      }
    }

    svg{
      fill:$daqua;

    }
  }

}