.pagination{
  font-family: $ani;
  text-align: center;
  font-size: 44px;
  margin-top: 10px;
  margin-bottom: 50px;

  .screen-reader-text{
    display: none;
  }

  a{
    color: $dblue;
    padding: 0 10px;

    &:hover{
      color: $blue;
    }
  }

  .current{
    color: $blue;
  }

  .nav-links{
    margin: 0 auto;
  }
}