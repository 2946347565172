.request{

  &__container{
    background-color: $yellow;
    padding: 40px;
  }

  &__block{

    &__name, &__type{
      border-bottom: 1px solid $black;
      padding: 10px 0 10px 15px;

      p{
        margin: 0;
        font-size: 22px;
      }
    }

    &__text{
      padding-left: 15px;
      font-size: 18px;
    }

  }

  .tags{
    margin-bottom: 0;
    li{
      margin-bottom: 0px;
    }

    a{
      background: $daqua;
      font-size: 18px;
    }

  }
}