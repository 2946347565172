.services{

  &__container{

  }

  &__block{
    background-color: $aqua;
    padding: 40px 30px 20px;

    img{
      max-width: 140px;
      max-height: 140px;
      box-shadow: $shadow;
      -webkit-border-radius: $img-radius;
      -moz-border-radius: $img-radius;
      border-radius: $img-radius;
    }


    &__text{
      padding: 0 25px;

      h3{
        font-family: $ani;
        font-size: 30px;
        margin: 0;
        line-height: 0.914;
      }

      p{
        margin: 0;
        line-height: 1.125;
      }
    }

  }

  &__btns{
    padding-right: 25px;
    margin-bottom: 25px;
    margin-top: 10px;

    a{
      width: 200px;
      height: 50px;
      font-size: 20px;
    }
  }

}