.contacts{


  &__container{
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  &__block{
    text-align: center;
    background-color: $yellow;
    font-size: 24px;
    padding:50px 35px;
    margin: 0 15px;

    &__img{

      img{
        box-shadow: $shadow;
        -webkit-border-radius: $img-radius;
        -moz-border-radius: $img-radius;
        border-radius: $img-radius;
      }
    }

    &__name{
      margin-top: 15px;
      font-weight: bold;
    }
  }
}