.trainers{

  .big-title svg {
    height: 60px;
    width: 42px;
  }

  &__block{
    background-color: $yellow;
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 35px 25px;

    img{
      max-height: 260px;
      max-width: 260px;
      box-shadow: $shadow;
      -webkit-border-radius: $img-radius;
      -moz-border-radius: $img-radius;
      border-radius: $img-radius;
    }

    &__content{
      font-size: 22px;
      margin-left: 30px;

      &__name{
        font-weight: bold;
      }

      &__text{
        font-size: 16px;
        margin-top: 5px;
      }

      &__tag{
        font-size: 18px;
        list-style: none;
        margin-top: 15px;

        li{
          margin-right: 5px;
          margin-bottom: 5px;
        }

        a{
          background: #a7dfe6;
          border-radius: 50px;
          padding: 5px 15px;
        }
      }
    }
  }

  &__more{
    margin-right: 15px;
  }

}