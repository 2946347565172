.feedback{
  text-align: center;
  margin-bottom: 70px;
  margin-top: 70px;

  input{
    border: none;

    &:not(.submit){
      width: 100%;
      font-size: 32px;
      padding: 19px 20px;
      border-radius: 50px;
      margin-top: 10px;
      background: $white;
      font-family: $ani;
    }

    &.submit{
      margin-top: 20px;
      cursor: pointer;
    }

    &::placeholder{
      font-family: $ani;
      font-size: 32px;
    }


  }


  &__container{
    background-color: $daqua;
    padding: 50px 120px;
    margin: 0px 45px;

  }

  .big-title{
    margin: 0 0 8px;
  }



}