.articles{


  &.main{
    margin-top: 40px;
  .btn-blue{
    margin-bottom: 15px;
  }
  }

  &__page{
    .big-title{
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .articles__container{
      background-color: $white;
    }
    .articles__block{
      padding: 40px 25px;
      margin-bottom: 35px;
      background-color: $aqua;
    }

  }



  &__container{
    padding: 10px 15px 90px;
    background-color: $aqua;

    .big-title{
      margin-top: 70px;
      margin-bottom: 30px;
    }
  }

  &__block{
    text-align: center;

    img{
      box-shadow: $shadow;
      -webkit-border-radius: $img-radius;
      -moz-border-radius: $img-radius;
      border-radius: $img-radius;
    }


    h3{
      font-size: 30px;
      font-family: $ani;
      margin-top: 12px;
      line-height: 1;
      font-weight: 600;
      min-height: 90px;
    }

    p{
      margin-top: 11px;
    }

    &__tag{
      list-style: none;
      margin-bottom: 18px;

      li{
        margin-right: 3px;
        margin-bottom: 5px;
      }

      a{
        background: $daqua;
        border-radius: 50px;
        padding: 2px 15px;
        font-size: 18px;
      }

    }
  }

  &__more{
    margin-top: 15px;
  }


  &__excerpt{
    max-height: 145px;
    overflow: hidden;
  }

}