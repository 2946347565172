@font-face {
  font-family: 'Avanti';
  src: url('../fonts/Avanti-Normal.eot');
  src: url('../fonts/Avanti-Normal.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Avanti-Normal.woff2') format('woff2'),
  url('../fonts/Avanti-Normal.woff') format('woff'),
  url('../fonts/Avanti-Normal.ttf') format('truetype'),
  url('../fonts/Avanti-Normal.svg#svgAvanti-Normal') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avanti';
  src: url('../fonts/Avanti-bold.eot');
  src: url('../fonts/Avanti-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Avanti-bold.woff2') format('woff2'),
  url('../fonts/Avanti-bold.woff') format('woff'),
  url('../fonts/Avanti-bold.ttf') format('truetype'),
  url('../fonts/Avanti-bold.svg#svgAvanti-bold') format('svg');
  font-weight: bold;
  font-style: normal;
}


body {
  font-family: "Avanti", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-size: 16px;
}


body a {
  text-decoration: none !important;
  color: $title;

  &:hover{
    color: $dblue;
  }
}

button{
  border: none;
}

button:hover, button:focus, button:active,
select:hover, select:focus, select:active,
input:hover, input:focus, input:active {
  outline: none!important;
}

h1, h2 {
  font-family: 'Amatic SC', cursive;
  line-height: 1;
}

ul {
  margin: 0;
  padding-left: 0;
}

.btn-yellow-short {
  background-image: url("../images/y-b.png");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  font-size: 20px;
  color: $black;
}

.btn-blue-short,
.btn-blue-long,
.btn-blue-extra,
.btn-aqua-short {
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  color: $white;
}
.btn-aqua-short {
  background-image: url('../images/b-a.png');
}
span{
  &.btn-blue{
    cursor: pointer;
  }
}
.btn-blue-short {
  background-image: url('../images/b-b.png');
}

.btn-blue-long {
  background-image: url('../images/b-b-l.png');
}

.btn-blue-extra {
  background-image: url('../images/b-b-e.png');
}

.big-title {
  text-align: center;
  font-size: 60px;
  color: $title;
  margin-bottom: 30px;
  text-transform: uppercase;

  svg{
    fill:$title;

    &.one{
      height: 75px;
      width: 50px;
    }

    &.two{
      height: 65px;
      width: 40px;
    }

    &.three{
      height: 60px;
      width: 42px;
    }

    &.four{
      height: 50px;
      width: 33px;
      margin-top: -15px;
    }

    &.five{
      height: 35px;
      width: 25px;
      transform: scale(1.5);
      margin-top: -15px;
      margin-right: 7px;
    }
  }
}

.frame {

  &.aqua{
    background: url(../images/top-aqua.svg) no-repeat 0% -1px,
    url(../images/bottom-aqua.svg) no-repeat 50% 100%;
    background-size: 100% 32px;

    &:after {
      background: url(../images/left-aqua.svg) no-repeat 100% 0%;
      background-size: 32px 100%;
    }
    &:before {
      background: url(../images/right-aqua.svg) no-repeat 100% 0%;
      background-size: 32px 100%;
    }
  }

  background: url(../images/top.svg) no-repeat 0% -1px,
  url(../images/bottom.svg) no-repeat 50% 100%;
  position: relative;
  background-size: 100% 32px;

  &:after {
    content: '';
    position: absolute;
    background: url(../images/left.svg) no-repeat 100% 0%;
    background-size: 32px 100%;
    height: 100%;
    width: 32px;
    top: 0;
    right: -8px;
  }

  &:before {
    content: '';
    position: absolute;
    background: url(../images/right.svg) no-repeat 0% 100%;
    background-size: 32px 100%;
    height: 100%;
    width: 32px;
    top: 0;
    left: -8px;
  }
}


.tags {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;

  li {
    margin-right: 3px;
    margin-bottom: 5px;

    &:hover{

      a{
        background: #8edee8;
      }

    }
  }

  a {
    border-radius: 50px;
    padding: 2px 15px;
    transition: background .3s;

  }
}


.mobile {

  &-btn {
    background: $daqua;
    z-index: 99;
    width: 50px;
    height: 50px;
    right: 15px;
    top: 15px;
    border-radius: 50px;
    cursor: pointer;


    &.active {

      div {
        height: 0px;

        &:after {
          margin-top: -2px;
          transform: rotate(45deg);


        }

        &:before {
          margin-top: -2px;
          transform: rotate(-45deg);
        }
      }
    }

    div {
      height: 4px;
      width: 30px;
      background: $white;


      &:after {
        content: '';
        height: 4px;
        width: 30px;
        background: $white;
        margin-top: 6px;
        position: absolute;
        transition: all .3s ease;

      }

      &:before {
        content: '';
        height: 4px;
        width: 30px;
        background: $white;
        margin-top: -6px;
        position: absolute;
        transition: all .3s ease;
      }
    }
  }

  &-menu {
    background: $aqua;
    width: 100%;
    height: 100vh;
    z-index: 10;
    transform: translateX(-100%);
    transition: transform .3s ease;
    text-align: center;

    &.active {
      transform: translateX(0%);
    }

    a {
      font-size: 20px;
    }

    img {
      margin-bottom: 15px;
    }

    li {
      border-bottom: .5px solid rgba($grey, .6);
    }

    .header__search{

      input{

        &:not(.submit){
          width: 100%;
          border-radius:50px;
          border:solid 1px $grey;
          font-size: 20px;
          padding: 5px 25px 5px 45px;
          position: relative;

          &::placeholder{
            color: $grey;
          }
        }

        &.submit{
          position: absolute;
          right: 0;
          top: 0;
          background:url("../images/search.svg") no-repeat;
          height: 42px;
          width: 70px;
          background-size: 20px;
          background-position-x: 50%;
          background-position-y: 50%;
          border-radius: 0 50px 50px 0;
          background-color: $blue;
          border: none;
        }

      }

    }

  }

  &-logo{
    img{
      height: 100px;
    }

  }



}

.yellow-btn {
  background-color: #edf297;
  transition: background-color .3s;

  &:hover {
    background-color: #F5F9B2;
  }
}

.short-btn{
  position: relative;
  font-size: 20px;

  &.yellow{

    svg{
      fill:$yellow;
      transition: fill .3s;

      &:hover{
        fill:$hyellow;
      }
    }

    text{
      fill:$black;
    }

  }

  &.blue{
    svg{
      fill:$blue;
      transition: fill .3s;

      &:hover{
        fill:$hblue;
      }
    }

    text{
      fill:$white;
    }
  }

  svg{
    height: 55px;
  }
}


/* The container must be positioned relative: */
.trainer-custom-select {
  position: relative;
  width: 100%;
  margin: auto;
  border: 30px;
  font-size: 20px;
  padding: 0 10px;
}

.trainer-custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: $blue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 20px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 14px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

.select-selected {
  border-radius: 25px;
}


.select-selected.select-arrow-active {
  border-radius: 25px 25px 0 0;
}

.select-items{

    border-radius: 0 0 25px 25px;

  div{

    &:last-child{
      border-radius: 0 0 25px 25px ;
    }
  }

}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: $blue;
  top: 100%;
  left: 10px;
  right: 10px;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.btn{


  &-yellow{
    background: $yellow;
    border-radius: 50px;
    transition: background .3s;
    padding: 10px 20px;
    color: $black;
    font-size: 20px;
    display: inline-block;
    min-width: 235px;
    text-align: center;

    &:hover{
      background: $hyellow;
      color: $black;
    }
  }

  &-blue{
    background: $blue;
    border-radius: 50px;
    transition: background .3s;
    padding: 10px 20px;
    color: $white;
    font-size: 20px;
    display: inline-block;
    min-width: 235px;
    text-align: center;

    &:hover{
      background: $hblue;
      color: $white;
    }
  }
}

.ajax-load{
  position: absolute;
  background: rgba(255,255,255,.6);
  width: 100%;
  height: 100%;
  top: 0;
  display: none;
}
.tab-content>.active {
  position: relative;
}

.svg{
  display: block;
  width: 40px;
  height: 40px;

  &:hover{

    svg{
      fill:$dblue;
    }
  }

  svg{
    fill:$blue;
    transition: fill .3s;
  }

  /*&.facebook{
    width: 40px;
    height: 40px;
  }
  &.instagram{
    width: 40px;
    height: 40px;
  }*/
}