.slider{
  position: relative;
  margin-top: 15px;
  margin-bottom: 35px;

  &__item{
    height: 495px;
    -webkit-border-radius: $img-radius;
    -moz-border-radius: $img-radius;
    border-radius: $img-radius;
  }

  &__content{
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 9;

    h1{
      color: $white;
      font-size: 65px;
      margin: 0;
    }

    p{
      color: $white;
      width: 800px;
      margin: 15px auto;
      font-size: 20px;
      line-height: 1.6;
      text-align: left;
    }

    &__btn{
      width: 250px;
      margin: 0 auto;
    }
  }

  .owl-dots{
    margin-top: 0px;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
  }

  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: $white;
  }

  .owl-theme .owl-dots .owl-dot span {
    background: rgba($white, .54);
  }

}