.map{

  iframe{
    width: 100%;
    height: 270px;
    border-radius: 25px 25px 0 0;
    margin-bottom: -6px;
    border: solid 1px $grey;
  }

  &__container{
    border-radius: 5px;
    overflow: hidden;
  }
}