.offer{
  padding: 60px 0 0;

  &__block{
    padding: 5px 20px;

    img{
      height: auto;
      max-width: 100%;
    }
  }

}