$aqua:#e6f4fb;
$daqua:#a7dfe6;
$blue:#0b95d6;
$dblue:#002aff;
$hblue:#2E7BA1;
$title:#1b3a93;
$yellow:#eef29b;
$hyellow:#F5F9B2;
$white:#fff;
$grey:#aeaeae;
$black:#121212;
$ubuntu:Ubuntu,sans-serif;
$ani:'Amatic SC', cursive;
$img-radius:15px;
$shadow:0px 1px 6px 0px rgba(0, 0, 0, 0.42);

@import "base.scss";
@import "../header/header";
@import "../breadcrumbs/breadcrumbs";
@import "../news/news";
@import "../reviews/reviews";
@import "../trainers/trainers";
@import "../pagination/pagination";
@import "../contacts/contacts";
@import "../services/services";
@import "../slider/slider";
@import "../single/single";
@import "../activity/activity";
@import "../articles/articles";
@import "../latest/latest";
@import "../request/request";
@import "../offer/offer";
@import "../about/about";
@import "../partners/partners";
@import "../footer/feedback/feedback";
@import "../footer/map/map";
@import "../footer/footer";
@import "../search/search";
@import "../responsive/responsive";
@import "../modal/modal";