.latest{
  margin-top: 40px;

  &__articles{
    margin-bottom: 25px;

    &__text{
      padding: 0 30px;

      h3{
        line-height: 0.914;
        font-size: 35px;
        font-family: $ani;
        margin-top: -3px;
        margin-bottom: 0;
        font-weight: 600;
      }

      p{
        margin-top: 3px;
        line-height: 1.125;
        margin-bottom: 0;
      }

    }

    &__img{

      img{
        max-height: 150px;
        max-width: 150px;
        box-shadow: $shadow;
        -webkit-border-radius: $img-radius;
        -moz-border-radius: $img-radius;
        border-radius: $img-radius;
      }
    }

    &__content{
      margin-bottom: 6px;
      min-height: 160px;
    }
  }

  &__rewiews{
    margin-bottom: 25px;

    &__text{

      h3{
        line-height: 0.914;
        font-size: 35px;
        font-family: $ani;
        margin-top: -3px;
        margin-bottom: 20px;
        font-weight: 600;
      }

      p{
        margin-top: 3px;
        line-height: 1.125;
        margin-bottom: 0;
      }

    }

    &__content{
      margin-bottom: 30px;
    }

    &__name{
      font-size: 12px;
      font-weight: bold;
      margin-top: 10px;
    }


  }


  .btn-blue-short{
    font-size: 20px;
    padding: 14px 53px;
    display: inline-block;
  }

  .btn-blue-extra{
    font-size: 20px;
    padding: 15px 0px;
    width: 100%;
    display: block;
    margin-top: 45px;
    text-transform: uppercase;
  }
}