.modal__feedback{

  .feedback{
    margin-bottom: 0px;
    margin-top: 0px;
    input{

      &:not(.submit) {
        padding: 5px 20px;
      }
    }

  }

  .feedback__container {
    padding: 0 15px;
    margin: 0 0px;
  }

  .big-title {
    font-size: 50px;
  }

  .modal-content{
    background: $daqua;
    border-radius: 15px;
  }

  textarea{
    width: 100%;
    border-radius: 25px;
    height: 150px;
    margin-top: 10px;
    padding-left: 15px;

    &:focus{
      outline: none;
    }

    &::placeholder{
      font-family: $ani;
      font-size: 30px;
    }
  }
}