.reviews{
  margin-top: 5px;

  &__container{

    h2{
      font-weight: 600;
      cursor: pointer;
    }
  }

  &__block{
    background-color: $daqua;
    padding: 35px;

    &__item{
      background: $yellow;
      padding: 15px;
      margin-bottom: 15px;
      border-radius: 25px;

      a{
        font-size: 30px;
        font-family: $ani;
        font-weight: bold;
      }
    }

    &__collapse{
      background: $yellow;
      border-radius: 25px;
      padding: 15px;
      margin-bottom: 15px;

      .reviews__block__item{
        padding: 0px;
        margin-bottom: 0px;
      }
    }

    &__name{

      a{
        font-size: 28px;
      }
    }

  }

  &__trainer{
    background: $yellow;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 25px;
  }

  &__title{
    font-size: 30px;
    font-family: $ani;
  }

  &__name{
    font-size: 18px;
  }

  &__position{
    font-weight: 600;
    font-size: 14px;
  }

  &__img{

    img{
      box-shadow: $shadow;
      -webkit-border-radius: $img-radius;
      -moz-border-radius: $img-radius;
      border-radius: $img-radius;
    }
  }
}