.activity{

  &-toggle{
    color: #fff;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0,0,0,.1);
    cursor: pointer;
    background-color: $blue;
    border-radius: 25px;
    font-size: 20px;
    padding: 8px 16px;
    margin: 0 10px 15px;
  }

  &.page{
    margin-bottom: 70px;

    .big-title{
      margin-top:10px;
    }
  }

  &__tabs{
    display: flex;
    margin-top: 0;
  }



  .nav-tabs .nav-link.active{
    background: $hblue;
    color: $white;
  }

  .nav-tabs .nav-link {
    border: none;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }

  .nav-tabs {
    border-bottom: none;
    padding: 0 10px;
  }

  .nav-tabs .nav-item{
    text-transform: uppercase;
    min-width: 235px;
    text-align: center;
    margin-bottom: 14px;
    margin-right: 5px;

    a{
      font-size: 20px;
      color: $white;
    }

    select{
      padding: 25px 35px 25px 20px;
      background: no-repeat;
      border: none;
      font-size: 20px;
      color: $white;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
      position: relative;
      background: url('../images/arrow.svg');
      background-repeat: no-repeat;
      background-position: 95% 50%;

      option{
        color: $black;
      }
    }
  }



  &__block{
    flex:0 0 50%;

    .frame{
      padding: 35px;
      flex: 0 0 50%;
      background-color: #eef29b;
    }

    &__left{
      height: auto;
      border-right:1px solid $grey;
      padding-right: 15px;
      flex: 0 0 35%;
      max-width: 35%;

      &__date{
        font-family: 'Homemade Apple', cursive;
        font-size: 18px;
      }

      &__type{
        border-top:1px solid $grey;
        border-bottom:1px solid $grey;
        padding: 14px 0;
      }

    }

    &__right{
      flex: 0 0 65%;
      max-width: 65%;
      padding-left: 15px;

      &__name{
        font-size: 14px;
        margin: 0 0 6px 0;
        min-height: 62px;

        a{
          font-family: 'Ubuntu', sans-serif;
        }
     }

      &__info{
        margin-top: 12px;
        border-top:1px solid $grey;

        &__price{
          flex: 0 0 38%;
          max-width: 38%;
          border-left:1px solid $grey;

          div{
            padding: 0 2.5px;
          }
        }

      &__trainer{
          flex: 0 0 62%;
          max-width: 62%;
          min-height: 44px;
        }
      }
    }

    .tags{

      padding-left: 5px;

      a{
        font-size: 18px;
        background: $daqua;
      }
    }
  }

  &__more{   
    margin-top: 15px;
    text-align: center;
    

  }

  &__modal{

    &.modal__feedback{
      .feedback input:not(.submit){
        margin-top: 0;
        padding: 0px 7px;
      }
    }



    label {
      margin-bottom: 0;
      margin-top: 15px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }





}