.header{

  &__container{
    border-bottom: solid .5px $grey;
  }

  &__logo{
    img{
      max-width: 160px;
    }
  }

  &__menu{
    list-style: none;
    background-color: $blue;
    margin-top: 0;
    padding: 0px 85px;
    border-radius: 0 0 25px 25px;
    position: relative;

    .wpglobus-current-language{

      &:hover{
        .sub-menu{
          display: block;
        }
      }

      .sub-menu{
        position: absolute;
        background: $blue;
        list-style: none;
        padding: 0 20px;
        left: 0px;
        z-index: 9;
        display: none;
      }
    }

    a{
      color: $white;
      font-size: 20px;
      display: block;
      padding: 15px;
      transition: background .3s;

      &:hover{
        background: $hblue;
        color: $white;
      }
    }


    .custom-change-lang {
      position: absolute;
      right: 20px;
      top: calc(50% - 10px);
    }
  }

  &__search{

    input{

      &:not(.submit){
        width: 100%;
        border-radius:25px;
        border:solid 1px $grey;
        font-size: 20px;
        padding: 18px 25px 18px 45px;
        position: relative;

        &::placeholder{
          color: $grey;
        }
      }

      &.submit{
        position: absolute;
        right: 0;
        top: 0;
        background:url("../images/search.svg") no-repeat;
        height: 100%;
        width: 100px;
        background-size: 40px;
        background-position-x: 50%;
        background-position-y: 50%;
        border-radius: 0 25px 25px 0;
        background-color: $blue;
        border: none;
      }

    }
  }

  &__social{
    list-style: none;
    padding-left: 0;
    margin-bottom: 10px;

    li{
      padding: 0 7px;
    }
  }
  
  &__callback{
    text-align: center;
    width: 260px;
    margin: 0 auto;
    cursor: pointer;

    div{
      padding: 15px;
    }
  }
  
  .custom-change-lang{
    color: $white;
    font-size: 14px;
    display: block;
    padding: 0px;
    text-transform: uppercase;
    font-weight: bold;

    a{
      padding: 0px;
      font-size: 14px;
    }

    .active{
      opacity: .5;
      cursor: pointer;
    }
  }


  .switch{
    width: 20px;
    height: 11px;
    border: solid 1px $white;
    margin: 0 5px;
    border-radius: 15px;
    margin-top: 2px;
    position: relative;


      &.right {

        &:after {
          left: calc(100% - 8px);
        }
      }

      &.left {

        &:after {
          right: calc(100% - 8px);
        }
      }


    &:after{
      content: "";
      width: 7px;
      height: 7px;
      background: $white;
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      border-radius: 30px;
      transition: left .3s,right .3s;
    }
  }

}