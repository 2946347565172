@media all and (max-width: 1530px){

  .header__menu {
    padding: 0 0 0 30px;

    li{

      &:last-child{
        padding-right: 15px;
      }
    }

    a{
      padding: 15px 7px;
      font-size: 18px;
    }
  }

  .trainers__block img {
    max-height: 200px;
    max-width: 200px;
  }

  .services__container {
    margin: 0;
    padding: 40px 30px;
  }

  .trainers__block{
    display: block;
    text-align: center;
  }
  .services__container {
     padding: 0px 0px;
  }




  .single__btn{

    .btn-blue{
      min-width: 330px;
    }
  }






}








@media all and (max-width: 1200px){

  .header__menu {
    padding: 0 30px 0;

    li{

    }

    a{
      padding: 15px 7px;
      font-size: 17px;
    }
  }

  .slider__content p {
    width: 100%;
    padding: 0 15px;
  }

  .activity .nav-item{
    min-width: auto;
    margin-right: 5px;
  }

  .activity__block__right__info__trainer,
  .activity__block__right__info__price{
    flex: 0 0 50%;
    max-width: 50%;
  }

  .articles__container {
    padding: 10px 0 15px;
  }

  .articles__block {
    padding: 0 25px;

    p{
      min-height: auto;
    }

    &__tag {
      margin-bottom: 5px;
    }

    .btn-blue-long{
      margin-bottom: 30px;
    }

  }

  .frame:after, .frame:before {
    height: 105%;
  }

  .big-title {
    font-size: 45px;

    img{
      margin: -10px -2px 0 -54px;
      transform: scale(.6);
    }
  }

  .page.about__container {
    padding: 32px 35px 65px;
  }
  .about__container {
    padding: 32px 40px 65px;
  }

  .articles__page .articles__container {
    padding: 66px 0px 15px;
    margin: 0 0px 20px;
  }


  .contacts__container {
    flex: 0 0 50%;
    max-width: 50%;
  }


  .activity__block {
    flex: 0 0 100%;
  }

  .activity__block__right__name {
    min-height: auto;
  }


  .latest{
    margin-top: 0;

    .big-title{
      margin-top: 15px;
    }
  }

  .big-title{
    margin-bottom: 15px;
  }
  .offer {
    padding: 20px 0 0;
  }

  .about__container {
    margin-bottom: 20px;
  }

  .feedback {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .single__btn{

    .btn-blue{
      margin-bottom: 10px;
    }
  }

  .articles__block h3 {
    min-height: auto;
  }


}









@media all and (max-width: 992px){
  .header{

    &__logo{
      margin-top: 10px;
    }
  }


  .about__container__advantages {
    font-size: 35px;

    div{
      &:first-child {
        font-size: 50px;
        line-height: 50px;
      }
    }
  }

  .footer__menu{
    padding-left: 0px;
  }


  .news{

    &__text {
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 15px;
    }

    &__img{

      img{
        max-width: 200px;
      }
    }

  }

  .contacts__block{
    font-size: 18px;

    &__img img {
      max-width: 100%;
      max-height: inherit;
    }
  }


  .partners__block {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }


  .mobile-menu li.custom-change-lang{
    border-bottom: none;
    justify-content: center;
    font-size: 18px;
    color: $title;
    margin-top: 15px;

    .switch{
      border-color: $title;
      width: 30px;
      height: 16px;

      &.right {

        &:after {
          left: calc(100% - 13px);
        }
      }

      &.left {

        &:after {
          right: calc(100% - 13px);
        }
      }

      &:after{
        background: $title;
        width: 12px;
        height: 12px;
      }
    }
  }

  .about__advantages {
    padding: 15px 0 0px;
  }

  .about__container.page {
     margin-bottom: 15px;
    padding: 0px 35px 25px;
   }







}









@media all and (max-width: 768px){

  .activity__block__right__info__trainer{
    min-height: auto;
  }
  .activity__block__right__info__trainer,
  .activity__block__right__info__price{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .activity__block__right__info__price{
    border-left: none;
  }
  .about__container__advantages {
    margin-bottom: 30px;
  }
  .partners__carousel{
    margin-bottom: 30px;

    .owl-nav {
     display: none;
    }
  }

  .feedback__container {
    padding: 50px 20px;
    margin: 0 0px;
  }


  .trainers__block{
    flex: 0 0 100%;
    max-width: 100%;
    padding: 40px 15px;

    &__content {
      margin-left: 0px;
    }
  }
  .big-title {
    margin-bottom: 15px;
  }

  .news{
    text-align: center;
  }
  .contacts__container {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .partners__block {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .services__block__img{
    text-align: center;
  }

  .search{

    img{
      margin: 0 auto 10px auto;
    }
  }

  .services__btns{

    .btn-blue{
      margin: auto;
    }
  }

  .services__block{

    img{
      margin: auto;
    }
  }

  .articles__container .big-title {
    margin-top: 30px;
  }

  .news{
    &__img {
      float: none;
      padding-right: 0px;
      text-align: center;
    }

    .big-title {
      margin-bottom: 15px;
      line-height: .5;
    }
    &__container {
      background-color: #e6f4fb;
      padding: 30px 20px 40px 20px;
    }
  }
  h3.reviews__block__item{
    font-size: 20px;
  }

  .services__block{
    text-align: center;

    h3{
      margin-bottom: 10px;
    }
  }
  .services__block__text {
    padding: 15px 0px 10px;
  }
  .services__btns {
    padding-right: 0px;

    .btn-blue{
      margin-bottom: 10px;
    }
  }
  .articles__page .articles__container {
    padding: 0px 0 15px;
  }



}


@media all and (max-width: 576px){

  .single__btn{
    .btn-blue{
      min-width: auto;
    }
  }

  .slider__content p {
    max-height: 195px;
    overflow: hidden;
    text-align: center;
  }

  .news__tag a {
    font-size: 14px;
  }

  .header{

    &__logo{
      text-align: center;
    }
  }

  .slider .owl-dots {
    display: none;
  }

  .slider__content {

    h1{
      font-size: 40px;
    }
  }

  .articles__block .btn-blue-long {
    padding: 14px 35px;
  }

  .big-title img {
    margin: -10px -2px 0 -15px;
  }
  .activity__block{
    text-align: center;
    .frame {
      padding: 27px 20px;
    }

    &__left {
      height: auto;
      border-right: none;
      padding-right: 0px;
      flex: 0 0 100%;
      max-width: 100%;
    }
    &__right {
      height: auto;
      border-right: none;
      padding-left: 0px;
      flex: 0 0 100%;
      max-width: 100%;
    }

  }
  
  .latest__articles{
    text-align: center;

    &__text{
      text-align: left;
      padding: 0;
      margin-top: 10px;
    }
  }

  .latest {
    margin-bottom: 30px;
  }
  .offer {
    padding: 0 0 30px;

    &__block {
      background: none;
      padding: 5px 0px;
    }
  }
  .footer__menu{
    text-align: center;
  }

  .feedback {

    input {

      &:not(.submit) {
        font-size: 18px;
        padding: 10px 20px;
      }

      &::placeholder {
        font-size: 18px;
      }

    }
  }

  .contacts__block {
    margin: 0 0px;
  }

  .partners__block {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .services__btns{
    padding-right: 0px;

    a {
      width: auto;
      margin-bottom: 10px;
    }
  }

  .services__container {
    padding: 0px 0px;
  }

  .request .tags {

    li {
      margin-bottom: 5px;
    }
  }
  .single__content{

    .single__img{
      float:none;
      margin: 0 auto;
      padding-right: 0px;
      text-align: center;
    }
  }

  .activity .nav-tabs .nav-item a {
    font-size: 16px;
  }


  .nav.activity__tabs{
    display: none;
  }


  .activity .nav-tabs .nav-item{
    margin-right: 0px;
  }

  .trainer-custom-select {
    font-size: 18px;
  }

  .big-title {
    font-size: 35px;
  }

  .big-title svg {
    transform: scale(.6);
    margin-right: -10px;
  }

  .articles__container .big-title {
    margin-bottom: 15px;
  }

  .big-title svg.four {
    margin-top: -5px;
  }
  .about .big-title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .breadcrumbs{
    text-align: center;
  }

  .single__container {
    padding: 45px 22px;
  }
  .page.about__container {
    padding: 32px 15px 25px;
  }
  .news__container {
    padding: 30px 15px 40px;
  }

  .reviews__block {
    padding: 35px 15px;
  }
  .services__block {
     padding: 40px 10px 20px;
   }
  .articles__block {
    padding: 0 15px;
  }
  .about__container {
    padding: 32px 15px 65px;
  }
  .articles__page .articles__block {
     padding: 40px 15px;
     margin-bottom:15px;
   }
  .single__container,
  .about__container,
  .reviews__block,
  .articles__block,
  .articles__container,
  .services__block ,
  .news__container {

    &.frame{

      &:before,&:after {
        background: none;
      }
    }
  }

  .single__btn .btn-blue {
    font-size: 18px;
  }

}